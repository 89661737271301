/* --------------------------------------------------------------------- */
/* Dynamic loading of link panel descriptions via UL4 view template URLs */
/* --------------------------------------------------------------------- */

$(function() {
	$('.panel--linkpanel .description[data-href]').each(function(i, element) {

		var descriptionElement = $(element);
		var descriptionHref = descriptionElement.data('href');

		$.get(descriptionHref).done(function(data) {
			descriptionElement.html(data);
		});

	});
});
