import Vue from 'vue';
import moment from 'moment/moment';
import 'magnific-popup';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import filter from 'lodash/filter';
import '../base/llprod-globals';

var FileSaver = require('file-saver');

(function ($, window, document, undefined) {
	$(function(){
		if( $('.attachments').length ) {
			llprod.globals.texts.__preloadcache__([
				'appbuilder_wizard_attachments_type_option_hint',
				'appbuilder_wizard_attachments_type_option_images',
				'appbuilder_wizard_attachments_type_option_file',
				'appbuilder_wizard_attachments_type_option_note',
				'appbuilder_wizard_attachments_type_option_url',
				'appbuilder_wizard_attachments_delete_dialog_warning_title',
				'appbuilder_wizard_attachments_delete_dialog_warning_message',
				'appbuilder_wizard_attachments_delete_dialog_button_title_delete',
				'appbuilder_wizard_attachments_delete_dialog_button_title_cancel',
				'appbuilder_wizard_attachments_notification_upload_success',
				'appbuilder_wizard_attachments_notification_upload_process',
				'appbuilder_wizard_attachments_notification_upload_encryption_process',
				'appbuilder_wizard_attachments_notification_upload_attachment_success',
				'appbuilder_wizard_attachments_notification_upload_attachment_fail',
				'appbuilder_wizard_attachments_error_missing_label',
				'appbuilder_wizard_attachments_notification_download_encryption_process',
				'appbuilder_wizard_attachments_notification_download_encryption_success'
			]);

			window.attachments = new Vue({
				"el": '.attachments',
				"mounted": function() {
					this.getUrlParameters();
					this.fetchAttachments();
				},
				"data": {
					"uuid": null,
					"dat_id": null,
					"loading": false,
					"submitted": false,
					"globalsInterval": null,
					"error": false,
					"errorType": '',
					"type": {
						"selected": '-1',
						"options": [
							{
								"text": 'appbuilder_wizard_attachments_type_option_hint',
								"value": '-1'
							},
							{
								"text": 'appbuilder_wizard_attachments_type_option_file',
								"value": 'file'
							},
							{
								"text": 'appbuilder_wizard_attachments_type_option_note',
								"value": 'note'
							},
							{
								"text": 'appbuilder_wizard_attachments_type_option_url',
								"value": 'url'
							}
						]
					},
					"attachments": [],
					"data": {
						"label": null,
						"type": null,
						"text": null,
						"file": null,
						"active": 1,
						"encryptionActive": false
					}
				},
				"filters": {
					"moment": function(date) {
						return moment(date).calendar();
					},
					"clip": function(attachment) {
						return Utils.clipStringAfter(attachment.text, 100, {"decorator": '... '});
					},
					"clipURL": function(attachment) {
						if (attachment.text){
							return Utils.clipStringAfter(attachment.text, 38, {"decorator": '...'});
						}
						return '';
					},
					"i18n": function(text) {
						return llprod.globals.texts.__gettext__(text);
					}
				},
				"methods": {
					"groupBy": function(list, type) {
						return groupBy(
							sortBy(list, function(o) {return o.created_at; }).reverse(),
							function (attachment) {
								return moment(attachment.created_at).fromNow();
							}
						);
					},
					"getUrlParameters": function() {
						var path = window.location.pathname, pathArray = path.split('/');
						if(pathArray && pathArray.length) {
							this.uuid = pathArray[2];
							if(path.indexOf('new') > 0) {
								this.dat_id = null;
							} else if(path.indexOf('edit') > 0) {
								this.dat_id = pathArray[3];
							} else if (pathArray.length > 3 && pathArray[3].length == 24) {
								this.dat_id = pathArray[3];
							} else {
								this.dat_id = null;
							}
						}
					},
					"getAttachmentDelIdFromUrl": function(attachment) {
						return attachment.url.substring(attachment.url.lastIndexOf('/') + 1);
					},
					"getDeleteWarningDialogLayout": function() {
						var lay = $(
							'<div class="white-popup-block custom">' +
								'<div class="ww-delete-warning-dialog-outer">' +
									'<div class="head">' +
										'<div class="headline">' + llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_delete_dialog_warning_title') + '</div>' +
										'<div class="description">' + llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_delete_dialog_warning_message') + '</div>' +
										'<p></p>' +
									'</div>' +
								'</div>' +
								'<div class="content">' +
									'<button class="button large primary red yes" type="submit">' + llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_delete_dialog_button_title_delete') + '</button>' +
									'<button class="button button-clear large cancel" type="submit">' + llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_delete_dialog_button_title_cancel') + '</button>' +
								'</div>' +
							'</div>'
						);
						return lay;
					},
					"showDeleteAttachmentWarningDialog": function(url) {
						var self = this, lay = this.getDeleteWarningDialogLayout();
						lay.find('.yes').click(function(e) {
							e.preventDefault();
							e.stopPropagation();
							self.deleteAttachmentByUrl($.magnificPopup.instance.userInfo.deleteUrl);
							$.magnificPopup.close();
						});
						lay.find('.cancel').click(function(e) {
							e.preventDefault();
							e.stopPropagation();
							$.magnificPopup.close();
						});
						$.magnificPopup.open({
							"items": {
								"src": lay
							},
							"modal": true,
							"type": 'inline',
							"callbacks": {
								"open": function() {
									this.userInfo = {
										"deleteUrl": url
									};
								}
							}
						}, 0);
					},
					"showAttachmentPreview": function(image, gallery) {
						var img, config, index, imgAtms;
						if (image){
							if (image.large){
								img = image.large;
							}else{
								img = image.original;
							}
							config = {
								"items": {
									"src": img
								},
								"type": 'image'
							};
							if(gallery){
								config.items = [];
								config.gallery = { enabled: true };

								imgAtms = filter(
									sortBy(this.attachments, x => x.created_at).reverse(),
									{"type": 'image'}
								);

								forEach(imgAtms, function(atm) {
									if (atm.image.large){
										config.items.push({
											src: atm.image.large
										});
									}else{
										config.items.push({
											src: atm.image.original
										});
									}
								});

								index = findIndex(imgAtms, {"image": image});
								$.magnificPopup.open(config, index);
							} else {
								$.magnificPopup.open(config);
							}
						}
					},
					"downloadFile": function(attachment) {
						if (attachment.image){
							return attachment.image.original + '?download=true';
						}
						if (attachment.file){
							return attachment.file.url + '?download=true';
						}
						return '#';
					},
					"deleteAttachmentByUrl": function(url) {
						var self = this, i, atm, realAtms = [];
						for (i = 0; i < this.attachments.length; i++) {
							atm = this.attachments[i];
							if (atm.url !== url){
								realAtms.push(atm);
							}
						}
						$.ajax(url, {
							"method": 'DELETE'
						}).success(function(data, textStatus) {
							self.attachments = realAtms;
						});
					},
					"handleTextExpand": function(attachment) {
						attachment.less = attachment.less ? false : true;
					},
					"handleFileValue": function(e) {
						var elm = e.target || e.srcElement;
						this.data.file = $(elm).val();
					},
					"fetchAttachments": function() {
						var self = this;
						this.loading = true;
						// Get Attachments
						if(this.uuid && this.dat_id) {
							$.getJSON('/gateway/apps/' + this.uuid + '/' + this.dat_id + '/attachments', {}, function(data) {
								self.loading = false;
								self.attachments = data.map(function(item) {
									if (item.image || item.file){
										if (item.image &&  item.image.mimetype === 'application/pgp' ||  item.file &&  item.file.mimetype === 'application/pgp') {
											item.encrypted = true;
										}
									}else{
										item.less = true;
									}
									return item;
								});
							});
						} else {
							this.loading = false;
						}
					},
					"initFileUploads": function() {
						var self = this, selector = '.attachments__fileupload', 
						opts = {
							"url": '/gateway/apps/' + this.uuid + '/' + this.dat_id + '/attachments',
							"uploadTemplateId": null,
							"downloadTemplateId": null,
							"dataType": 'json',
							"autoUpload": false,
							"replaceFileInput": false,
							"add": function (e, data) {
								$('.attachments form').off('submit').on('submit', function (e) {
									e.stopPropagation();
									e.preventDefault();
									if (self.data.file){
										if (self.data.encryptionActive) {
											llprod.notificationRowHandler('processing', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_upload_encryption_process'), 120000);
											formbuilder.cryptedForm.encryptFile(data, function () {
												data.formData = pickBy(self.data, identity);
												llprod.notificationRowHandler('processing', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_upload_process'), 120000);
												data.submit();
											});
										} else {
											data.formData = pickBy(self.data, identity);
											if (self.data.file) {
												llprod.notificationRowHandler('processing', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_upload_process'), 120000);
											}
											data.submit();
										}
									}
								});
							},
							"done": function(e, data) {
								// This callback is triggered too, if we select other types then image or file.
								// Dont no why that behavior exists.
								if (self.type.selected === 'image' || self.type.selected === 'file'){
									llprod.notificationRowHandler('success', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_upload_success'));
									self.fetchAttachments();
									self.data.file = null;
									$(this).val('');
								}
							}
						};
						if (this.type.selected === 'image' || this.type.selected === 'file'){
							if (this.type.selected === 'image'){
								selector += '--image';
								opts.acceptFileTypes = '/(.|\/)(gif|jpg|jpeg|png)$/i';
							}else if (this.type.selected === 'file'){
								selector += '--file';
							}
							if (!$(selector).data('blueimp-fileupload')){
								$(selector).fileupload(opts).bind('fileuploadsubmit', function (e, data) {
								});
							}
							
						}
					},
					"submitAttachment": function(e) {
						var self = this;
						e.preventDefault();
						if (this.type.selected !== 'image' && this.type.selected !== 'file') {
							this.submitted = true;
							this.error = false;
							this.errorType = '';
							// Handle Note and URL Submit
							if (this.data.label){
								if (this.type.selected === 'url' && !this.data.text){
									this.error = true;
									this.errorType = 'missing_url_text';
								}else{
									$.post('/gateway/apps/' + this.uuid + '/' + this.dat_id + '/attachments', pickBy(this.data, identity), function(data) {
										self.submitted = false;
										llprod.notificationRowHandler('success', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_upload_attachment_success'));
									}, 'json')
									.done(function(e) {
										if (self.type.selected !== 'image' || self.type.selected !== 'file'){
											self.fetchAttachments();
										}
									})
									.fail(function() {
										self.submitted = false;
										llprod.notificationRowHandler('error', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_upload_attachment_fail'));
									});
								}
							} else {
								this.error = true;
								this.errorType = 'missing_label';
							}
						}
					},
					"verifyEncryption": function(attachment, e){
						var url;
						if (attachment.encrypted) {
							e.stopPropagation();
							e.preventDefault();
							url = e.target.href;
							formbuilder.cryptedForm.decryptFile(url, (blob, filename) => {
								llprod.notificationRowHandler('success', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_download_encryption_success'));
								FileSaver.saveAs(blob, filename);
							});
							llprod.notificationRowHandler('processing', llprod.globals.texts.__gettext__('appbuilder_wizard_attachments_notification_download_encryption_process'), 120000);
						}
					}
				},
				"updated": function() {
					this.initFileUploads();
				},
				"watch": {
					"type.selected": function(newVal, oldVal) {
						this.error = false;
						this.errorType = '';
						this.data.type = newVal;
						this.data.label = null;
						this.data.text = null;
						this.data.file = null;
						this.data.encryptionActive = false;
					}
				}
			});
		}
	});
})(ll.jQuery, window, document);